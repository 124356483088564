import { useFormContext } from "@graphcommerce/ecommerce-ui";
import { useMutation, useQuery } from "@graphcommerce/graphql";
import { i18n } from "@lingui/core";
import { Trans } from "@lingui/react";
import { Button } from "@mui/material";
import { useContext, useEffect, useState, useCallback } from "react";
import { ModalContext } from "../../context/context";
import { useLoading } from "../../context/loadingContext";
import { createAccountOTPVerifyDocument } from "../../graphql/login/createAccountOtpVerify.gql";
import { createCustomerAccountDocument } from "../../graphql/login/createCustomerAccount.gql";
import { loginOTPVerifyDocument } from "../../graphql/login/loginOTPVerify.gql";
import IconArrowLeftShort from "../icons/IconArrowLeftShort";
import IconPen from "../icons/IconPen";
import { TextFieldElement } from "../widgets/Form/TextFieldElement";
import useLogin from "./useLogin";
// eslint-disable-next-line import/no-cycle
import { FormType } from ".";

type SendCodeFormProps = {
  setActiveForm: (formType: FormType) => void;
  data: any;
  stayAtPage?: boolean;
};

const SendCodeForm = ({ setActiveForm, data, stayAtPage }: SendCodeFormProps) => {
  const [counter, setCounter] = useState(120);
  const useModal = useContext(ModalContext);
  const { refetch } = useQuery(createAccountOTPVerifyDocument, { skip: true });
  const { refetch: verifyCode } = useQuery(loginOTPVerifyDocument, { skip: true });
  const [createCustomerAccount] = useMutation(createCustomerAccountDocument);
  const methods = useFormContext();
  const { setLoading } = useLoading();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const { handleLogin } = useLogin();

  useEffect(() => {
    if (counter <= 0) return;
    const timer = setTimeout(() => setCounter((prevCounter) => prevCounter - 1), 1000);
    return () => clearTimeout(timer);
  }, [counter]);

  const handleSubmit = useCallback(
    async (values) => {
      setLoading(true);
      try {
        if (data.mode === "createAccount") {
          const { data: verifyData, errors } = await refetch({
            mobileNumber: data.mobileNumber,
            otp: values.otp,
          });
          if (errors) throw new Error(verifyData?.createAccountOTPVerify?.message);
          if (!verifyData?.createAccountOTPVerify?.status) {
            setErrorMessage(verifyData?.createAccountOTPVerify.message);
            return;
          }
          const { data: accountData } = await createCustomerAccount({
            variables: {
              firstname: data.firstname,
              lastname: data.lastname,
              date_of_birth: data.date_of_birth,
              email: data.email,
              gender: data.gender,
              mobileNumber: data.mobileNumber,
              password: data.password,
              otp: values.otp,
            },
          });
          if (accountData?.createCustomerAccount?.status && accountData.createCustomerAccount.token) {
            setSuccessMessage(i18n._("The registration operation was completed successfully."));
            handleLogin(accountData.createCustomerAccount.token, null, true, stayAtPage);
          }
        } else if (data.mode === "loginOtp") {
          const { data: loginData, errors } = await verifyCode({
            mobileNumber: data.mobileNumber,
            otp: values.otp,
          });
          if (errors) throw new Error(loginData?.loginOTPVerify?.message);
          if (loginData?.loginOTPVerify?.status && loginData.loginOTPVerify.token) {
            handleLogin(loginData.loginOTPVerify.token);
            setSuccessMessage(i18n._("Login was successful."));
            setErrorMessage(null);
          } else {
            setErrorMessage(loginData?.loginOTPVerify?.message);
          }
        }
      } catch (error) {
        setErrorMessage(error.message);
      } finally {
        setLoading(false);
      }
    },
    [createCustomerAccount, data, handleLogin, refetch, setLoading, stayAtPage, verifyCode]
  );

  return (
    <div className="pt-16 md:pt-5">
      {errorMessage && <div className="text-sm text-error-500 font-normal text-center my-4">{errorMessage}</div>}
      {successMessage && <div className="text-sm text-secondary-300 font-normal text-center my-4">{successMessage}</div>}
      <p className="text-base font-medium">
        <Trans id="Verification of mobile phone number" />
      </p>
      <form onSubmit={methods.handleSubmit(handleSubmit)}>
        <p className="text-xs text-light-400">
          <Trans id="Please enter the code sent via SMS in the field below:" />
        </p>
        <TextFieldElement
          name="otp"
          label={i18n._("5 digit code")}
          required
          fullWidth
          type="otp"
          variant="standard"
          classes={{ root: "!mt-5" }}
          className="!text-sm"
          color="primary"
        />
        <div className="flex justify-center items-center mt-4">
          {counter > 0 ? (
            <p className="text-base font-light">
              {i18n._("Ability to resend the code up to")} <span className="font-medium">{counter}</span> {i18n._("Another second")}
            </p>
          ) : (
            <Button type="button" variant="text" color="primary" className="flex justify-center text-primary-500">
              <Trans id="Resend the code" />
            </Button>
          )}
        </div>
        <div className="flex justify-center items-center mt-4">
          <Button type="submit" variant="contained" color="secondary" className="flex justify-center bg-secondary-500">
            <Trans id="Final approval" />
          </Button>
        </div>
        <div className="mt-7 flex justify-between">
          <Button type="button" className="text-light-400 !rounded-sm" onClick={() => setActiveForm(data?.mode === "createAccount" ? FormType.signup : FormType.code)}>
            <IconPen width={14} height={14} />
            <Trans id="Change mobile phone number" />
          </Button>
          <Button type="button" className="text-light-400 !rounded-sm" onClick={useModal.close}>
            <Trans id="Close" />
            <IconArrowLeftShort width={18} height={18} />
          </Button>
        </div>
      </form>
    </div>
  );
};

export default SendCodeForm;
